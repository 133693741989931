import React, { useState, Fragment } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import validator from 'validator'

function BusinessOpportunity() {

  const [companyName, setbusCompany] = useState('');
  const [BfullName, setbusFname] = useState('');
  const [BcontactNo, setbusContact] = useState('');
  const [Bemail, setbusEmail] = useState('');
  const [position, setbusPosition] = useState('');
  const [messageBox, setbusMessage] = useState('');
  const [busAgree, setbusAgree] = useState(false);
  const [checkboxError, setCheckBoxError] = useState(false)
  const [companyNamereq, setbusCompanyreq] = useState(false);
  const [BfullNamereq, setbusFnamereq] = useState(false);
  const [BcontactNoreq, setbusContactreq] = useState(false);
  const [Bemailreq, setbusEmailreq] = useState(false);
  const [positionreq, setbusPositionreq] = useState(false);
  const [messageBoxreq, setbusMessagereq] = useState(false);
  const [busAgreereq, setbusAgreereq] = useState(false);
  const [appplyBtnBs, setappplyBtnBs] = useState('CONNECT');
  const [BusEmailError, setBusEmailError] = useState('Please enter this content');

  const BusSubmit = () =>
  {
    let formdata = {
      companyName : companyName,
      fullName : BfullName,
      contactNo : BcontactNo,
      email : Bemail,
      position : position,
      messageBox : messageBox
    }
    if ( busAgree === false) {
      setCheckBoxError(true)
    }
    
    // console.log("formdata", formdata);
    if(BfullName != '' && BcontactNo != '' && Bemail !='' && busAgree)
    {
      if (validator.isEmail(Bemail)) {
        setBusEmailError('Please enter this content');
        setbusFnamereq(false);
        setbusContactreq(false);
        setbusEmailreq(false);
        let business_url = 'https://mlmsotctva.execute-api.ap-southeast-1.amazonaws.com/addemail/business';
        setappplyBtnBs('SENDING...');
        fetch(business_url, {
            method: 'POST',
            body: JSON.stringify(formdata),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data =>{
              console.log(data)
              clearInputs()
              Swal.fire({
                icon: 'success',
                text: 'Thank you for connecting, we will be sending you a confirmation email and our admin will contact you soon',
                confirmButtonClass: "btn-primary btn",
                confirmButtonText: "OK",
              })
            })
            .catch(err => setappplyBtnBs('CONNECT'));
      }
      else{
        setbusEmailreq(true);
        setBusEmailError('Please enter a valid email address');
      }
    }
    else{
      if(BfullName == '')
      {
        setbusFnamereq(true);
      }
      if(BcontactNo == '')
      {
        setbusContactreq(true);
      }
      if(Bemail == '')
      {
        setbusEmailreq(true);
      }
    }
    
  }

  const clearInputs = () => 
  {
    setappplyBtnBs('SENT');
    setTimeout(function(){
      setappplyBtnBs('CONNECT');
    }, 1000)
    setbusCompany('');
    setbusFname('');
    setbusContact('');
    setbusEmail('');
    setbusPosition('');
    setbusMessage('');
    console.log('sent');
  }

  return (
    <div className="business-opportunity pt-1" id="business-opportunity">
      <div className="row">
        <div className="col-md-7 ta-left">
            <div className="row">
              <p className="f-sfui left content">
                View some of our collaborations with business partners and
                celebrities.
              </p>
            </div>
            <div className='business-image-container'>
                <img src="/assets/business/mobile.png" className='mobile-img'></img>
                <img src="/assets/business/mobile-bg.png" className='mobile-bg'></img>
                <img src="/assets/business/business-04.jpeg" className='business-img b4'></img>
                <img src="/assets/business/business-03.png" className='business-img b3'></img>
                <img src="/assets/business/business-02.png" className='business-img b2'></img>
                <img src="/assets/business/business-01.png" className='business-img b1'></img>

            </div>
          {/* <img className="business-image" src="/assets/pic_business.png" /> */}
        </div>
        <div className="col-md-5">
          <div className="plr-20p ta-right ta-center-mobile pt-1">
          <p className="right content">We always open our doors to every business opportunity, and gladly support all sorts of business cooperation. Please do not hesitate in reaching out!</p>
            <div className="row">
              <h1 className="header">Business Opportunity</h1>
            </div>
            <div className="row">
              <input className="form-control" placeholder="Company Name" name="companyName" value={companyName} onChange={(e) => setbusCompany(e.target.value)}/>
                
            </div>
            <div className="row">
              <input className={(BfullNamereq ? "require-input " : "") + "form-control"} placeholder="Full Name" value={BfullName} name="BfullName" onChange={(e) => setbusFname(e.target.value)}/>
                {
                  BfullNamereq ? <span className='require-error'>Please enter this content</span> : <></>
                }
            </div>
            <div className="row">
              <input className={(BcontactNoreq ? "require-input " : "") + "form-control"} placeholder="Contact Number" value={BcontactNo} name="BcontactNo" type="text" onChange={(e) => setbusContact(e.target.value)}/>
                {
                  BcontactNoreq ? <span className='require-error'>Please enter this content</span> : <></>
                }
            </div>
            <div className="row">
              <input className={(Bemailreq ? "require-input " : "") + "form-control"} placeholder="E-mail Address" value={Bemail} name="Bemail" onChange={(e)=> setbusEmail(e.target.value)}/>
                {
                  Bemailreq ? <span className='require-error'>{BusEmailError}</span> : <></>
                }
            </div>
            <div className="row">
            <input className={(positionreq ? "require-input " : "") + "form-control"} placeholder="Position" value={position} name="position" onChange={(e)=> setbusPosition(e.target.value)}/>
                {
                  positionreq ? <span className='require-error'>Please enter this content</span> : <></>
                }
            </div>
            <div className="row">
              <input className="form-control" placeholder="Message Box" name="messageBox" value={messageBox} onChange={(e) => setbusMessage(e.target.value)}/>
            </div>
            <div className="row form-check">
              <input type="checkbox" class="form-check-input" id="BusinessagreeCheck" checked={busAgree} onChange={e => { setbusAgree(e.target.checked); setCheckBoxError(!e.target.checked) }} />
              <label class="form-check-label" for="BusinessagreeCheck" id="Businessagree">
              By filling up this form, you agree to our <a href='https://www.bigo.tv/agreement.html'>Terms of Use</a> and 
                <a href='https://www.bigo.tv/privacyPolicy.html'> Privacy Policy</a>
              </label>
            </div>
            <div>
                {checkboxError ? <Fragment><label style={{color: 'red'}}>Please aggree to the Terms of Use and Privacy Policy to continue.</label></Fragment> : null}
              </div>
            <div className="row apply">
              <button type="button" class="f-bigo btn btn-primary" onClick={() => BusSubmit()}>
                {appplyBtnBs}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessOpportunity;
