import {  Button, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { render } from "react-dom";

export default class Contact extends Component {
    constructor() {
        super();
        this.state = {
            store : true,
            social : false
        }
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    appStoreClick()
    {
        
        this.setState({store : true});
        this.setState({social : false});
    }

    socialClick()
    {
        this.setState({store : false});
        this.setState({social : true});
    }

    render(){
        return (
            <div className="contact-us mandatory-scroll-snapping" id="contact-us">
              <div className="row justify-content-between contact-us-columns-container">
                <div className='col-md-6 contact-us-columns'>
                    <h1 className='header'>Contact Us</h1>
                    <div className='row f-sfui contact-details'>
                        <div className='col-6 ta-left-mobile'>
                            <ul className='business'>
                                <li>
                                    <img src="/assets/icon/ic_email.svg" className='icon'/>
                                    <div className='content'>
                                        <span class="business-name">Agency/Talent cooperations</span><br/>
                                        <a class="business-contact" href="mailto:g-ph-businessdev@bigo.sg">g-ph-businessdev@bigo.sg</a>
                                    </div>
                                </li>
                                <li>
                                    <img src="/assets/icon/ic_email.svg" className='icon'/>
                                    <div className='content'>
                                        <span class="business-name">Business cooperations</span><br/>
                                        <a class="business-contact" href="mailto:g-ph-mkt@bigo.sg">g-ph-mkt@bigo.sg</a>
                                    </div>
                                    
                                </li>
                            </ul>
                        </div>
                        <div className='col-4 socmed-links'>
                            <ul className='social'>
                                <li>
                                    <img src="/assets/icon/ic_ins.svg" className='icon'/>
                                    <div className='content'>
                                        <a class="business-name" href="https://www.instagram.com/bigolivephilippines">Instagram</a><br/>
                                    </div>
                                </li>
                                <li>
                                    <img src="/assets/icon/ic_fb_white.svg" className='icon'/>
                                    <div className='content'>
                                        <a class="business-name" href="https://www.facebook.com/BIGOLIVEphilippines">Facebook</a><br/>
                                    </div>
                                </li>
                                <li>
                                    <img src="/assets/icon/ic_youtube.svg" className='icon'/>
                                    <div className='content'>
                                        <a class="business-name" href="https://www.youtube.com/channel/UCRDAeS4iXAfOoQUIUn5vBvw">YouTube</a><br/>
                                    </div>
                                </li>
                                <li>
                                    <img src="/assets/icon/ic_tiktok.svg" className='icon'/>
                                    <div className='content'>
                                        <a class="business-name" href="https://www.tiktok.com/@bigolive_ph">TikTok</a><br/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row f-sfui'>
                        <ul className='footer-link'>
                            <li>
                                <a href='https://www.bigo.sg/about'>About BIGO</a>
                            </li>
                            <li>
                                <a href='https://www.bigo.tv/agreement.html'>TERMS OF USE</a>
                            </li>
                            <li>
                                <a href='https://www.bigo.tv/privacyPolicy.html'>PRIVACY POLICY</a>
                            </li>
                        </ul>
                        <p className='recaptcha-note'>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </p>                   
 </div>
                </div>
                <div className='col-md-4 text-right mb-120 contact-us-columns'>
                    <div className='widget-container text-center'>
                        <div className={(this.state.store ? '' : 'd-none ') + 'qr-container'}>
                            <img class="dino-head" src="/assets/pic_dino_head.png"/>
                            <div className={'app-container'}>
                                <span className='qr-header'>Scan to download</span>
                                <img className='qr-code' src='/assets/BIGOLIVE%20App安装码.png' />
                                <div className='app-store'>
                                    <a className='app-link' href="https://apps.apple.com/us/app/bigo-live-live-stream-go-live/id1077137248"><img src="/assets/ic_appstore.svg" /></a>
                                    <a className='app-link' href="https://play.google.com/store/apps/details?id=sg.bigo.live&hl=en&gl=US"><img src="/assets/ic_googleplay.svg" /></a>
                                    <a className='app-link' href="https://appgallery.huawei.com/Featured"><img src="/assets/ic_huaweistore.svg" /></a>
                                </div>
                            </div>
                            <div className='arrow-right'></div>
                        </div>
                        <div className={(this.state.social ? '' : 'd-none ') + 'social-container'}>
                            <span className='social-header'>Join our Groups to get a head start! </span>
                            <ul className='social-icons'>
                                <li>
                                    <a href='https://t.me/joinchat/sZhsFuz1lmczYWFl?fbclid=IwAR05oYNQ074z5RrpQ6W4nMhvR_Jkasr0fohkNiL2IsrDl_UIsrN4W4iT33Y' className='icons telegram'><img src="/assets/icon/ic_telegram.svg" /></a>
                                </li>
                                <li>
                                    <a href='https://www.facebook.com/groups/bigoagencyhunt/' className='icons telegram'><img src="/assets/icon/ic_fb.svg" /></a>
                                </li>
                                <li>
                                    <a href='https://invite.viber.com/?g2=AQBcO8ohFHraM054BXY2i1yvDL%2BoSEcOmlyxg4kIxSYIyg8Dnjn0cJwF5kWDuS35' className='icons telegram'><img src="/assets/icon/ic_viber.svg" /></a>
                                </li>
                            </ul>
                            <div className='arrow-right'></div>
                        </div>
                        
                    </div>
                    
                    <ul className='circle-icons'>
                        <li>
                            <div className={(this.state.store ? 'active ' : '') +  'circle'} onClick={() => this.appStoreClick()}>
                                {
                                    this.state.store ? 
                                    <img src="/assets/ic_app_select.svg" />
                                    :
                                    <img src="/assets/ic_app.svg" />
                                }
                                
                            </div>
                        </li>
                        <li>
                            <div className={(this.state.social ? 'active ' : '') +  'circle'} onClick={() => this.socialClick()}>
                                {
                                    this.state.social ? 
                                    <img src="/assets/ic_follow_select.svg" />
                                    :
                                    <img src="/assets/ic_follow.svg" />
                                }
                            </div>
                        </li>
                        <li>
                            <div className='circle' onClick={() => this.scrollToTop()}>
                                <a href='#about-us'><img src="/assets/ic_top.svg" /></a>
                            </div>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          );
    }
  
}

// export default Contact;
