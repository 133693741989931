import {  Button, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import Slider from 'react-styled-carousel';

function GlobalEvent() {
  let carol = [
        {
            'title' : 'Event Name', 
            'src' : '/assets/carousel/event-mini-04.png'
        },
        {
            'title' : 'Event Name', 
            'src' : '/assets/carousel/event-mini-01.png'
        },
        {
            'title' : 'Event Name', 
            'src' : '/assets/carousel/event-mini-05.png'
        },
        {
            'title' : 'Event Name', 
            'src' : '/assets/carousel/event-mini-02.png'
        },
        {
            'title' : 'Event Name', 
            'src' : '/assets/carousel/event-mini-03.png'
        },
        
        
    ]
      
  return (
    <div className="global-event mandatory-scroll-snapping" id="global-event">
        <div className="row">
            <div className='col-md-6 '>
                <h1 className='header'>Global Events</h1>
            </div>
            <div className='col-md-6 text-right center-mobile ta-left-mobile'>
                <Button className='f-bigo pull-right'>EXPLORE MORE <img src="/assets/ic_jump_up.svg" /></Button>
            </div>
        </div>
        <div className='row mt'>
            <div className='col-md-12'>
                <Slider showArrows={false} showDots={false}>
                    {
                        carol.map(function(data){
                            return (
                                <div className='carousel-bigo'>
                                    <img src={data.src} />
                                    <div className='bg-blue'></div>
                                    <p className='title'>{data.title}</p>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    </div>
  );
}

export default GlobalEvent;
