import {  Button, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import ReactDOM from 'react-dom';
import React, { Component, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bigo from '../video/bigo.MP4';
import { Waypoint } from 'react-waypoint';
import ic_play from '../assets/ic_play.svg';
import { useParallax } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

function GlobalPresence() {

  let [shouldPlay, updatePlayState] = useState(false);
  const [ytlink] = useState("https://www.youtube.com/watch?v=DuGJEyEjaJo");

  let handleEnterViewport = () => {
    // console.log('focused');
    updatePlayState(true);
  }
  let handleExitViewport = () => {
    updatePlayState(false);
  }
  const { refPresence } = useParallax<HTMLDivElement>({ speed: 10 });

  const [width, setWidth] = useState(window.innerWidth);
  
  const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

  const setOffsetYMax=(windowWidth) => {
		return windowWidth <= 480 ? 0 : 10;
	}

	const setOffsetYMin=(windowWidth) => {
		return windowWidth <= 480 ? 0 : -120;
	}

	const disableParallax = (windowWidth) => {
		return windowWidth <= 480;
	}

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange());
  
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange());
      }  
  })
  


  return (
    <Waypoint 
            onEnter={handleEnterViewport}
            onLeave={handleExitViewport}
          >
      <div className="global-presence " id="global-presence" >
        <div className='row'>
              
              <div className='col-md-7 mobile order-2-mobile' speed={0} offsetYMax={setOffsetYMax(width)}
						offsetYMin={setOffsetYMin(width)}>
                
                  <div className='player-wrapper'  >
                    <ReactPlayer light={"/assets/thumbnail.png"} playIcon={<img src={ic_play} />} playsinline muted={false} playing={shouldPlay} url={ytlink} className='stream' controls = {true} />
                  </div>
                  <div className=''>
                  <Button href="https://www.bigo.tv/ph/" className='f-bigo watchlive'>Watch LIVE now <img src="/assets/ic_jump_right.svg" /></Button>
                  </div>
              </div>
              
              <div className='col-md-5 order-1-mobile' speed={0} offsetYMax={setOffsetYMax(width)}
						offsetYMin={setOffsetYMin(width)}>
                  <h1 className='header'>Global<br/>Presence</h1>
                  <p className='content'>Bigo Live is a global live streaming social platform launched in March 2016 by BIGO Technology (BIGO) - one of the fastest-growing Singapore technology companies powered by Artificial Intelligence. Bigo Live currently has over 400 million users in over 150 countries.
                  </p>
                  <Button href="https://www.bigo.sg/" className='f-bigo exploremore'>Explore more <img src="/assets/ic_jump_right.svg" /></Button>
              </div>
            
        </div>
      </div>
    </Waypoint>
  );
}

export default GlobalPresence;
