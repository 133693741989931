import React, { Fragment, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Parallax } from 'react-scroll-parallax';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import validator from 'validator'

// import { scroller } from "react-scroll";

const artists = [
  {
    "file_name": "emmie.png",
    "name": "Emie Conjurado",
    "bigo_id": "emieloveee",
  },
  {
    "file_name": "empress.png",
    "name": "Maria Luisa Abano Varela",
    "bigo_id": "EmpressPH",
  },
  {
    "file_name": "ninja.png",
    "name": "Jannine Cartagena",
    "bigo_id": "ninjajannine",
  },
  {
    "file_name": "cess.png",
    "name": "Princess Angeles",
    "bigo_id": "Prime_Cess",
  },
];

function JoinUs(){

  const [applyLink, setapplyLink] = useState('');
  const [fullName, setjoinName] = useState('');
  const [contactNo, setJoinContact] = useState('');
  const [role, setjoinRole] = useState('');
  const [email, setJoinEMail] = useState('');
  const [telegramUsername, setjoinTeletegram] = useState('');
  const [facebookAddress, setjoinFb] = useState('');
  const [viberNo, setjoinViber] = useState('');
  const [joinAgree, setjoinAgree] = useState(false);
  const [checkboxError, setCheckBoxError] = useState(false)
  const [fullNameReq, setfullNameReq] = useState(false);
  const [contactNoReq, setcontactNoReq] = useState(false);
  const [roleReq, setroleReq] = useState(false);
  const [emailReq, setemailReq] = useState(false);
  const [telegramUsernameReq, settelegramUsernameReq] = useState(false);
  const [facebookAddressReq, setfacebookAddressReq] = useState(false);
  const [viberNoReq, setviberNoReq] = useState(false);
  const [joinAgreeReq, setjoinAgreeReq] = useState(false);
  const [appplyBtn, setappplyBtn] = useState('APPLY');
  const [emailError, setEmailError] = useState('Please enter this content');
  const [fireArtist, setfireArtist] = useState([
    {
      active : true,
      id : 'emieloveee'
    },
    {
      active : false,
      id : 'ecstacy69'
    }
  ]);
  const [fireContentColor, setFireContentColor] = useState(null);

  const MySwal = withReactContent(Swal)
  
  let formdata = {
    role : '',
    fullName : '',
    contactNo : '',
    email : '',
    telegramUsername : '',
    facebookAddress : '',
    viberNo : '',
    checked : ''
  }
  

  const fireToggle = (id) =>{
    let tempArt = [];
    let active = '';
    fireArtist.map(function(data){
      data.active = data.id == id ? true : false;
      tempArt.push(data);
      
    });
    setfireArtist(tempArt);
    // scrollToSection('#' + id);
  }

  // const scrollToSection = (id) => {
  //   scroller.scrollTo(id, {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // };

  const joinSubmit = () =>
  {
    formdata = {
      role : role,
      fullName : fullName,
      contactNo : contactNo,
      email : email,
      telegramUsername : telegramUsername,
      facebookAddress : facebookAddress,
      viberNo : viberNo,
      checked : joinAgree
    }
    
    if (joinAgree === false) {
      setCheckBoxError(true)
    }
    
    if(role != '' && fullName != '' && contactNo != '' && email != '' && joinAgree)
    {
      // MySwal.showLoading();
      if (validator.isEmail(email)) {
        setEmailError('Please enter this content');
        setroleReq(false);
        setfullNameReq(false);
        setcontactNoReq(false);
        setemailReq(false);
        let signup = 'https://mlmsotctva.execute-api.ap-southeast-1.amazonaws.com/addemail/signup';
        setappplyBtn('SENDING...');
        fetch(signup, {
            method: 'POST',
            body: JSON.stringify(formdata),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => {
              console.log(data)
              clearInputs()
              Swal.fire({
                icon: 'success',
                text: 'Thank you for applying, we will be sending you a confirmation email and our admin will contact you soon',
                confirmButtonClass: "btn-primary btn",
                confirmButtonText: "OK",
              })
            })
            .catch(err => console.error("Error:", err));
      } else {
        setemailReq(true);
        setEmailError('Please enter a valid email address');
      }
      
    }
    else{
      
      if(role == '')
      {
        setroleReq(true);
      }
      if(fullName == '')
      {
        setfullNameReq(true);
      }
      if(contactNo == '')
      {
        setcontactNoReq(true);
      }
      if(email == '')
      {
        setemailReq(true);
      }
      if(telegramUsername == '' && facebookAddress == '' && viberNo == ''){
        settelegramUsernameReq(true);
        setfacebookAddressReq(true);
        setviberNoReq(true);
      }

    }

  }

  const clearInputs = () => 
  {
    setappplyBtn('SENT');
    setTimeout(function(){
      setappplyBtn('APPLY');
    }, 1000)
    setjoinName('');
    setJoinContact('');
    setJoinEMail('');
    setjoinTeletegram('');
    setjoinFb('');
    setjoinViber('');
    setjoinAgree('');
    
  }

  const artistlist = artists.map((artist) => (
    <div className="img-content" style={{ backgroundImage: `url("/assets/artists/slider/${artist.file_name}")` }}>
      <div className="context" id={artist.bigo_id}>
        <h3>{artist.name}</h3>
        <span>ID:{artist.bigo_id}</span>
      </div>
    </div>
  ));
  
  
  const handleSlideChange = (index) => {
    if(index != 3){
      setFireContentColor('white')
    }else{
      setFireContentColor('#9C40FF')
    }
  };
  
  // render(){
    return (
      <div className="join-us " id="join-us">
        <div className="row mobile-padding-top join-us-columns-container">
          <div className="col-md-5 join-us-columns" speed={0}>
            <div className="plr-20p ta-left">
              <br/>
                <p className='content'>BIGO LIVE is home to 30 thousand Filipino hosts, whose diversity is warmly welcomed. We accept you proudly, whether you are a LGBTQ, a disabled person, or whoever you define yourself to be.</p>
              <div className="row">
                <h1 className="header">Join Us</h1>
              </div>
              <div className="row">
                <select className={(roleReq ? "require-input " : "") + "form-select"} aria-label="Select role" name="role" value={role} onChange={(e) => {setjoinRole(e.target.value);setapplyLink(e.target[e.target.selectedIndex].getAttribute('data-link'))}}>
                  <option className="select-items" value="" data-link='#' selected>Which role are you applying for?</option>
                  <option className="select-items" value="Host" data-link="http://bit.ly/3ZXspKq">Host </option>
                  <option className="select-items" value="Agency" data-link="https://www.facebook.com/groups/338314221279128/">Agency </option>
                </select>
                {
                  roleReq ? <span className='require-error'>Please select this content</span> : <></>
                }
                
              </div>
              {/* <div className="row">
                <input className={(fullNameReq ? "require-input " : "") + "form-control"} placeholder="Full Name" value={fullName} name="fullName" onChange={(e) => setjoinName(e.target.value)} />
                {
                  fullNameReq ? <span className='require-error'>Please enter this content</span> : <></>
                }
              </div>
              <div className="row">
                <input className={(contactNoReq ? "require-input " : "") + "form-control"} placeholder="Contact Number" value={contactNo} name="contactNo" onChange={(e) => setJoinContact(e.target.value)}/>
                {
                  contactNoReq ? <span className='require-error'>Please enter this content</span> : <></>
                }
              </div>
              <div className="row">
                <input className={(emailReq ? "require-input " : "") + "form-control"} placeholder="E-mail Address" value={email} name="email" onChange={(e) => setJoinEMail(e.target.value)} />
                {
                  emailReq ? <span className='require-error'>{emailError}</span> : <></>
                }
              </div>
              <div className="row">
                <input className="form-control" placeholder="Telegram Username" name="telegramUsername" value={telegramUsername} onChange={(e) => setjoinTeletegram(e.target.value)} />
                {
                  telegramUsernameReq ? <span className='require-error'>Please enter this content</span> : <></>
                }
              </div>
              <div className="row">
                <input className="form-control" placeholder="Facebook Address" name="facebookAddress" value={facebookAddress} onChange={(e) => setjoinFb(e.target.value)}/>
                {
                  facebookAddressReq ? <span className='require-error'>Please enter this content</span> : <></>
                }
              </div>
              <div className="row">
                <input className="form-control" placeholder="Viber Number" name="viberNo" value={viberNo} onChange={(e) => setjoinViber(e.target.value)}/>
                {
                  viberNoReq ? <span className='require-error'>Please enter this content</span> : <></>
                }
              </div>
              <div className="row form-check">
                <input type="checkbox" class="form-check-input" id="agree" checked={joinAgree} onChange={e => { setjoinAgree(e.target.checked); setCheckBoxError(!e.target.checked) }} />
                <label class="form-check-label" for="agree" id="agree">
                  By filling up this form, you agree to our <a href='https://www.bigo.tv/agreement.html'>Terms of Use</a> and
                  <a href='https://www.bigo.tv/privacyPolicy.html'> Privacy Policy</a>
                </label>
              </div>
              <div>
                {checkboxError ? <Fragment><label style={{color: 'red'}}>Please aggree to the Terms of Use and Privacy Policy to continue.</label></Fragment> : null}
              </div> */}
              <div className="row apply">
                {/* <button type="button" class="f-bigo btn btn-primary" onClick={() => joinSubmit()}>
                  {appplyBtn}
                </button> */}
                <a href={applyLink} class="f-bigo btn btn-primary">
                  {appplyBtn}
                </a>
              </div>
            </div>
            <div className="socmed-links f-sfui">
              <div>
              <span>Join our Groups to get a head start</span>
              <img src="/assets/ic_jump_left.png"/>
              </div>
              <a href='https://t.me/joinchat/sZhsFuz1lmczYWFl?fbclid=IwAR05oYNQ074z5RrpQ6W4nMhvR_Jkasr0fohkNiL2IsrDl_UIsrN4W4iT33Y'>
                <button className="telegram">
                  <img className="ic" src="/assets/icon/ic_telegram_white.png" />
                  Telegram
                </button>
              </a>
              <a href='https://www.facebook.com/groups/bigoagencyhunt/'>
                <button className="facebook">
                  <img className="ic" src="/assets/icon/ic_fb_white.png" />
                  Facebook
                </button>
              </a>
              {/* <a href='https://invite.viber.com/?g2=AQBcO8ohFHraM054BXY2i1yvDL%2BoSEcOmlyxg4kIxSYIyg8Dnjn0cJwF5kWDuS35'>
                <button className="viber">
                  <img className="ic" src="/assets/icon/ic_viber_white.png" />
                  Viber
                </button>
              </a> */}
              <div>
              <em>"Please note that putting up your own Agency or signing up as a Host requires ZERO CAPITAL and will forever be FREE of charge."</em>
              </div>
            </div>
          </div>
          <div className="col-md-7 ta-left join-us-columns" speed={0}>
            {/* <img className="artist hide-mobile" src="/assets/emie.png" /> */}
            <div className='fire-line-container'><div className='fire-line'></div></div>
              <div className='fire-container'>
              <Carousel autoPlay={true} infiniteLoop={true} swipeable={true} onChange={handleSlideChange}>
              {artistlist}
              </Carousel>
                {/* <div className='scroll-container'>
                  <ul className='scroller'>
                    <li><div className={fireArtist[0].active ? 'active' : 'circle'} onClick={() => fireToggle('emieloveee')} to="#emieloveee"></div></li>
                    <li><div className={fireArtist[1].active ? 'active' : 'circle'} onClick={() => fireToggle('ecstacy69')} to="#ecstacy69"></div></li>
                  </ul>
                </div>                
                <div className='img-content emie-bg' id="emieloveee">
                  <div className='context'>
                    <h3>EMIE</h3>
                    <span>ID:emieloveee</span>
                  </div>
                  
                </div>
                <div className='img-content emps-bg' id="ecstacy69">
                    <div className='context'>
                      <h3>EMPS ALBANO</h3>
                      <span>ID:ecstacy69</span>
                    </div>
                </div> */}
              </div>
                <p className="f-sfui fire-content" style={{ color: fireContentColor }}>Meet our Host and Agency Manager!</p>
          </div>
        </div>
        
      </div>
    );
  }
  
// }

export default JoinUs;
