import logo from './logo.svg';
import './App.css';
import React, { Component, useEffect, useState } from 'react';
import Home from './Components/Home';
import About from './Components/About';
import GlobalPresence from './Components/GlobalPresence';
import Navivation from './Components/Nav';
import GlobalEvent from './Components/GlobalEvent';
import Contact from './Components/Contact';
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import JoinUs from './Components/JoinUs';
import BusinessOpportunity from './Components/BusinessOpportunity';
import $ from 'jquery';
import { useParallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {  
  useEffect(()=>{
    $(document).ready(function(){
      var myContainer = $('.dino');
      var sensitivityMultiplier = 0.03;
      var wrapperOffset = myContainer.offset();
      var CenterX = wrapperOffset.left + (myContainer.width()/2) ;
      var CenterY = wrapperOffset.top + (myContainer.height()/2) ;
      
      $(window).mousemove(function(e) {
      var mouseX = e.pageX;
      var mouseY = e.pageY;
      doAwesomeness(mouseX,mouseY);
      });
      
     function doAwesomeness(mouseX,mouseY){
       var RelX = ( mouseX - CenterX ) * sensitivityMultiplier;
       var RelY = ( ( mouseY - CenterY ) * -1 ) * sensitivityMultiplier;
       myContainer.css('-webkit-transform', 'translateY(' + RelX + 'px) translateX(' + RelY + 'px)' );
       myContainer.css('transform', 'translateY(' + RelX + 'px) translateX(' + RelY + 'px)' );
     };
      
     });     

     $(document).ready(function(){
      var scroll = $(window).scrollTop();

      if(scroll >= 0 && scroll <= 600){
        $(".about-us").addClass("active");
      }
      else{
        $(".about-us").removeClass("active");
      }
     });

     $(window).scroll(function(){
      var scroll = $(window).scrollTop();

      // if (scroll >= 0 && scroll <= 700) {
      //     $(".nav-about-us").addClass("active");
      // } else {
      //     $(".nav-about-us").removeClass("active");
      // }
      // if (scroll > 800 && scroll <= 1480) {
          
      //     $(".nav-global-presence").addClass("active");
      // } else {
      //     $(".nav-global-presence").removeClass("active");
      // }
      // if (scroll > 1580 && scroll <= 2700) {
      //     $(".nav-join-us").addClass("active ");
      // } else {
      //     $(".nav-join-us").removeClass("active");
      // }
      // if (scroll > 2750 && scroll <= 3600) {
      //     $(".nav-business-opportunity").addClass("active");
      // } else {
      //     $(".nav-business-opportunity").removeClass("active");
      // }
      // if (scroll >= 3600 && scroll <= 5030) {
      //     $(".nav-contact-us").addClass("active");
      // } else {
      //     $(".nav-contact-us").removeClass("active");
      // }


      //Transition
      if(scroll >= 0 && scroll <= 600){
        $(".about-us").addClass("active");
      }
      else{
        $(".about-us").removeClass("active");
      }
      if(scroll >= 600 && scroll <= 1480){
        $(".global-presence").addClass("pad-140");
        $(".global-presence").addClass("active");
      }
      else{
        $(".global-presence").removeClass("active");
        $(".global-presence").removeClass("pad-140");
      }
      if(scroll >= 1600 && scroll <= 2700){
        $(".join-us").addClass("pad-140");
        $(".join-us").addClass("active");
      }
      else{
        $(".join-us").removeClass("active");
        $(".join-us").removeClass("pad-140");
      }
      if(scroll >= 2750 && scroll <= 3830){
        $(".business-opportunity").addClass("pad-9m");
        $(".business-opportunity").addClass("active");
      }
      else{
        $(".business-opportunity").removeClass("active");
        $(".business-opportunity").removeClass("pad-9m");
      }
      // if(scroll >= 4000){
      //   $(".contact-us").addClass("active");
      // }
      // else{
      //   $(".contact-us").removeClass("active");
      // }
     });

  })



  return (
    <ParallaxProvider>
      <div className='bigo-container'>
        <Navivation/>
        <div className='App'>
          <About/>
          <GlobalPresence/>
          {/* <GlobalEvent/> */}
          <JoinUs/>
          <BusinessOpportunity/>
        </div>
        <Contact/> {/*  intended to be outside */}
      </div>
    </ParallaxProvider>
  );
}

export default App;
