import {  Button, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import ReactDOM from 'react-dom';
import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParallax } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

function About() {
  const { refAbout } = useParallax<HTMLDivElement>({ speed: 10 });

  const [width, setWidth] = useState(window.innerWidth);
  
  const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

  const setOffsetYMax=(windowWidth) => {
		return windowWidth <= 480 ? 0 : 10;
	}

	const setOffsetYMin=(windowWidth) => {
		return windowWidth <= 480 ? 0 : -120;
	}

	const disableParallax = (windowWidth) => {
		return windowWidth <= 480;
	}

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange());
  
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange());
      }  
  })
  

  return (
    <div className="about-us " id="about-us" ref={refAbout}>
      <div className="row">
        <div className='col-md-6 text-center' speed={0} offsetYMax={setOffsetYMax(width)}
						offsetYMin={setOffsetYMin(width)}>
          <h1 className='header'>Better BIGO, <br/>Better Future!</h1>
          <p className='f-sfui about-content'>Being the most competitive livestream social platform in the Philippines, BIGO LIVE embraces you to show your life and talents, while bringing all Filipinos closer together. Our family has over 4 million members in the Philippines, pressing 200 million footprints in the world. Let us work on the bright path ahead.
          </p>
          <Button href="#join-us" className='f-bigo'>JOIN US NOW <img src="/assets/ic_jump_right.svg" /></Button>
        </div>
        <div className='col-md-6' speed={0} offsetYMax={setOffsetYMax(width)}
						offsetYMin={setOffsetYMin(width)}>
          <img className='dino' src="/assets/dino.png" />
        </div>
      </div>
    </div>
  );
}

export default About;
