import { Navbar, Nav } from 'react-bootstrap';

function Navigation() {
  return (
    <Navbar expand="xl">
        <div className='w-100 d-flex'>
            <Navbar.Brand href="#home"><img src="/assets/logo.svg" /> </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
                <Nav.Link href="#about-us" className='about-us-nav nav-about-us' onClick={toggleCollapsed}><span >About us <hr className='show-mobile'/></span></Nav.Link>
                <Nav.Link href="#global-presence" className='nav-global-presence' onClick={toggleCollapsed}>Explore more <hr className='show-mobile'/></Nav.Link>
                <Nav.Link href="#join-us" className='nav-join-us' onClick={toggleCollapsed}>Join us <hr className='show-mobile'/></Nav.Link>
                <Nav.Link href="#business-opportunity" className='nav-business-opportunity' onClick={toggleCollapsed}>Business opportunity <hr className='show-mobile'/></Nav.Link>
                <Nav.Link href="#contact-us" className='nav-contact-us' onClick={toggleCollapsed}>Contact us <hr className='show-mobile'/></Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </div>
    </Navbar>
  );
}

function toggleCollapsed(){
  const navToggler = document.querySelector('.navbar-toggler');
  navToggler.classList.toggle('collapsed');
  const fsToggler = document.querySelector('#basic-navbar-nav');
  fsToggler.classList.toggle('show');
}

export default Navigation;
